import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './InfoRow.module.css';

const initData = [
  {
    shortText: 'Отбираем лучшие продукты и гарантируем качество',
    fullText:
      'Некачественным продуктам будет очень трудно прорваться через наш пятиступенчатый контроль качества, но если несмотря ни на что вам все же что-то не понравится, просто пришлите нам после заказа фотографию этого продукта, и мы вернем за него деньги или предложим через 2 часа произвести замену.',
  },
  {
    shortText: 'Бережно упаковываем и быстро доставляем за 290р или бесплатно ',
    fullText:
      'Мы базируемся в центре Москвы, поэтому 99% заказов доставляем точно в назначенный часовой интервал. От 5000 руб доставка бесплатная. Охлажденные продукты кладутся в термопакеты, ягоды и нежные фрукты - в прочные контейнеры, а остальное - в биоразлагаемые и крафт-пакеты.',
  },
  {
    component: (
      <div key="component" styleName="item short-text" style={{ maxWidth: '25%' }}>
        <div styleName="line header">
          <div>Сумма заказа</div>
          <div>Доставка</div>
        </div>
        <div styleName="line">
          <div>от 2500р</div>
          <div styleName="points">.....................................................................</div>
          <div>290р</div>
        </div>
        <div styleName="line">
          <div>от 7000р</div>
          <div styleName="points">.....................................................................</div>
          <div>Бесплатно</div>
        </div>
      </div>
    ),
  },
];

const InfoRow = ({ className, data, ...props }) => {
  const [tab, setTab] = useState();
  const toggleTab = index => () => setTab(index === tab ? -1 : index);
  return (
    <div styleName="root" className={className} {...props}>
      {data.map(
        ({ component, ...item }, i) =>
          component || (
            <div key={item.shortText} styleName="item" role="presentation" onClick={toggleTab(i)}>
              <div styleName="short-text hover">{item.shortText}</div>
              {i === tab && <div styleName="popup">{item.fullText}</div>}
            </div>
          )
      )}
    </div>
  );
};

InfoRow.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
};

InfoRow.defaultProps = {
  className: undefined,
  data: initData,
};

export default InfoRow;
