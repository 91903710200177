import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet-async';
import Analytics from 'components/Analytics';
import withSizes from 'react-sizes';
import Footer from 'components/Footer';
import MobileFooter from 'components/MobileFooter';
import Logo from 'components/icons/Logo';
import FirstHeader from 'components/FirstHeader';
import MobileHeader from 'components/MobileHeader';
import Recommendations from 'components/Recommendations';
import InfoRow from 'components/InfoRow';
import AddToCart from 'components/AddToCart';
import withAvoidHydrate from 'hocs/withAvoidHydrate';

import s from './product.module.css';

const Product = ({ data, size, onlyDetox }) => {
  const { magento = {} } = data || {};
  const { product, cachedCatalog = {} } = magento;
  const { recommendations } = cachedCatalog;
  const width = (size && size.width) || 1280;
  const mobile = width <= 666;
  const perRow = Math.floor(width / 320);

  return (
    <React.Fragment>
      {mobile && (
        <Helmet>
          <meta name="viewport" content="width=666, user-scalable=no" />
        </Helmet>
      )}
      <Helmet title={product.name} />
      <Analytics
        type="viewItem"
        id={product.entity_id}
        price={product.finalPrice > product.price ? product.finalPrice : product.price}
      />
      <div styleName="root">
        {mobile ? <MobileHeader onProduct /> : <FirstHeader onProduct />}
        <Link to={mobile ? '/mob' : '/'}>
          <Logo styleName="logo" />
        </Link>
        <div styleName="face">
          <div styleName="face__left">
            <img itemProp="image" src={product.image} alt="" styleName="cover" />
          </div>
          <div styleName="face__right">
            <div>
              <h1 styleName="face__title">{product.name}</h1>
              <p styleName="face__description">{product.description}</p>
            </div>
            <AddToCart product={product} />
          </div>
        </div>
        <InfoRow styleName="info-row" />
        {product.story && (
          <div styleName="full-description">
            {product.story.split(/[\n\r]+/).map(line => (
              <p key={line}>{line}</p>
            ))}
          </div>
        )}
        <div styleName="recommendations-wrapper">
          <Recommendations
            onlyDetox={onlyDetox}
            classNameOfCard={s.recommendations__card}
            products={recommendations[0].products}
            perRow={perRow}
          />
        </div>
        {mobile ? <MobileFooter /> : <Footer />}
      </div>
    </React.Fragment>
  );
};

export const query = graphql`
  query ProductPage($pagePath: String!) {
    magento {
      product(path: $pagePath) {
        ...ProductCard
        image: mainImage(width: 542)
        entity_id
        story
      }
      cachedCatalog {
        recommendations {
          products(all: true) {
            ...ProductCard
          }
        }
      }
    }
  }
`;

Product.propTypes = {
  data: PropTypes.object,
  size: PropTypes.object.isRequired,
  onlyDetox: PropTypes.object,
};

Product.defaultProps = {
  data: undefined,
  onlyDetox: false,
};

export default withAvoidHydrate(withSizes(size => ({ size }))(Product));
