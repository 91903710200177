import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'components/hocs';
import Dropdown from './Dropdown';
import Button from './Button';

import s from './AddToCart.module.css';

const getButtonText = (notAvailable, isAdded) =>
  notAvailable ? 'Нет в наличии' : isAdded ? 'Добавлено в корзину' : 'Добавить в коризну'; // eslint-disable-line no-nested-ternary

const AddToCart = ({ className, quantity, product, isAdded, globalActions }) => {
  const { price, finalPrice, by_request: notAvailable } = product;

  const changeQuantity = q => globalActions.cart.add(product, q);
  const add = () => globalActions.cart.add(product);
  const buttonText = getButtonText(notAvailable, isAdded);
  const withSale = finalPrice && finalPrice < price;

  return (
    <div styleName="root" className={className}>
      <div styleName="price">
        <div styleName="price__amount">
          {withSale && <span>{quantity * finalPrice} р</span>}
          <span className={withSale ? s.old : ''}>{quantity * price} р</span>
        </div>
        <Dropdown
          styleName="dropdown"
          controlClassName={s.dropdown__control}
          placeholderClassName={s.dropdown__placeholder}
          arrowClassName={s.dropdown__arrow}
          value={quantity}
          onChange={changeQuantity}
          data={product}
          forceDropdown
        />
      </div>
      <Button onClick={!notAvailable && add} disabled={notAvailable} styleName="button">
        {buttonText}
      </Button>
    </div>
  );
};

AddToCart.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired,
  isAdded: PropTypes.bool.isRequired,
  quantity: PropTypes.number.isRequired,
  globalActions: PropTypes.object.isRequired,
};

AddToCart.defaultProps = {
  className: undefined,
};

const selector = (state, props) => ({
  isAdded: state.cart.addedIds.indexOf(props.product.entity_id),
  quantity: state.cart.quantityById[props.product.entity_id] || props.product.fresh_step || 1,
});

const enhance = connect(selector);

export default enhance(AddToCart);
